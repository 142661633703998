@import "../../../assets/styles/variables";

@mixin menu {
  .ant-menu-urc {
    box-shadow: none;
    border-bottom-width: 0;
    font-family: "Open Sans";

    .ant-menu-item {
      font-weight: bold;
      text-transform: uppercase;
      .capitalize {
        color: black;

        text-transform: capitalize;
      }
      
      &.ant-menu-item-selected {
        background-color: #f4d9b3;
        border-bottom-color: transparent;
        a {
          color: #7a4521;
        }
      }
      a {
        cursor: pointer;
        text-align: left;
        color: black;
        &::after {
          display: block;
          content: attr(data-menu-label);
          font-weight: bold;
          height: 1px;
          color: transparent;
          overflow: hidden;
          visibility: hidden;
        }
      }
      &.ant-menu-item-active {
        border-bottom-color: #f4d9b3;
        a {
          color: #6f3c1e;
        }
      }
    }
    &.right {
      .ant-menu-item {
        font-weight: bold;
        border-bottom-color: transparent;
        &.search {
          i {
            font-size: 1.4rem;
            margin: 0;
            vertical-align: middle;
          }
        }
        &.login-signup {
          font-weight: normal;
          background-color: #df0313;
          a {
            color: #ffffff;
          }
        }
      }
    }
    @content;
  }
}
.ant-layout-header {
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: background 0.2s, width 0.2s;
  padding-right: 0;
  .logged-in-name {
    color: white;
  }
  

  @include menu;

  .logo {
    width: 140px;
    height: 70px;
    background-image: url("../../../assets/images/flour_logo.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    margin: 0 0.5rem 0.8rem 0;
    float: left;
  }
}
.mobile {
  .ant-layout-header {
    .drawer-toggle {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      color: $text-primary-color-active;
      left: 1rem;
      i {
        font-size: 1.5rem;
      }
    }
    .logo {
      width: 120px;
      height: 38px;
      background-image: url("../../../assets/images/flour_logo.png");
      margin: 0.8rem auto;
      float: none;
      display: block;
    }
  }
}
.main-menu-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-wrapper-body {
      display: flex;
      flex-direction: column;
      .ant-drawer-header {
        padding: 0;
        .ant-drawer-title {
          .ant-card {
            .ant-card-head {
              border: none;
              .logo {
                width: 90px;
                height: 30px;
                background-image: url("../../../assets/images/flour_logo.png");
                background-repeat: no-repeat;
                background-size: auto 100%;
                margin: 0;
                float: left;
              }
              button {
                color: $text-primary-color-active;
              }
            }
            .ant-card-body {
              display: none;
            }
            &.has-content {
              .ant-card-body {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 8px;
                h3 {
                  flex: 1;
                  margin: 0;
                  padding-left: 1rem;
                  font-weight: 100;
                  max-width: 148px;
                  a {
                    color: #000000;
                    font-weight: 400;
                    display: inline-block;
                    overflow: hidden;
                    max-width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
        }
      }
      .ant-drawer-body {
        padding: 0;
        display: flex;
        flex: 1;
        flex-direction: column;
        .ant-menu:first-child {
          flex: 1;
        }
      }
    }

    @include menu {
      .ant-menu-item {
        margin: 0;
        height: auto;
        a {
          padding: 0.4rem 0;
          text-align: left;
          &:after {
            display: none;
          }
        }
        &:after {
          display: none;
        }
        &.login-signup {
          background-color: #df0313;
          a {
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }
  }
}
.notification-row {
  // width: 200px;
}

.notification-menu {
  min-width: 400px;
  max-height: 80vh;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
}

.notification-title-container {
  border-bottom: 1px solid #eaeaea;
  .notification-title {
    font-family: "Open Sans";
    font-size: 0.9rem;
    font-weight: 600;
    color: #9f9f9f;
    padding: 15px 25px;
    margin: 0px;
  }
}

.notification-sorter {
  //
}
.notification-item {
  cursor: pointer;
  &:hover {
    background-color: #eaeaea;
  }
}

.unread-notification {
  // background-color: #eee;
  background-color: #aaa;
}

.read-notification {
  background-color: #fff;
}

.notification-sortby {
  padding-left: 25px;
  font-family: "Montserrat";
  font-size: 0.9rem;
  padding-top: 10px;
}
.notification-sorter-dropdown {
  font-family: "Open Sans";
  font-size: 0.9rem;
}
.notification-description {
  line-height: 160.68%;
  color: #404040;
  display: flex;
  padding: 20px 20px;
  align-items: center;
  text-transform: capitalize;
  font-size: 0.8rem;

  justify-content: space-between;
  &:hover {
    background-color: #eaeaea;
  }
  > div:last-child {
    flex-basis: 5%;
    align-items: center;
  }

  > div:first-child {
    flex-basis: 95%;
    display: flex;
    align-items: center;
  }
  > .notification-active-indicator {
    text-align: right;
  }

  p {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 14px;
    margin: 0;
  }
}

.notification-date {
  font-family: "Open Sans";
  font-size: 0.7rem;
  text-transform: capitalize;
  color: #9f9f9f;
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-left: 30px;
}
// today

.notification-secondary-header {
  font-family: "Montserrat";
  font-size: 20px;
  line-height: 24px;
  color: #202020;
  padding: 20px 20px 0px 25px;
}

.notification-zero {
  padding: 10px 20px;
}

.notification-footer{
  font-family: "Montserrat";
  text-align: center;
}

.notification-page-button {
  color: #932222;
  font-weight: 600;
  font-size: 15px;

  &:active {
    color: #932222;
    font-weight: 600;
    font-size: 15px;
  }

  &:hover {
    color:  #df0313;
    font-weight: 600;
    font-size: 15px;
  }

  &:focus {
    color:  #df0313;
    font-weight: 600;
    font-size: 15px;
  }
  &:visited {
    color:  #df0313;
    font-weight: 600;
    font-size: 15px;
  }
  #triangle-up {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid white;
    position: absolute;
    top: -20px;
    right: 15%;
  }
}