$text-accent-color: #58452f;
$main-background: #fcf8f2;
$main-border: #efeae3;
$active-border: #e8d1b0;
$active-background: #f0e5d7;
$active-title-background: #fce8ca;
$text-primary-color: #666666;
$text-secondary-color: #7f7f7f;
$text-primary-color-active: #df0313;
$strip-background: #a80f1b;
$strip-background-secondary: #f8f8f8;
$secondary-border: #e5e5e5;
$button-primary-color: #bf914a;
$text-white-color: #ffffff;
