@import '../../assets/styles/variables';

@media only screen and (max-width: 810px) {
  .user-profile-container {
    padding: 5vh 2vh !important;

    .user-profile-card {
      display: block !important;

      .sider-content {

        .sider-content-header {
          min-height: 155px !important;
          justify-content: flex-end !important;
        }
      }

      .user-profile-header {

        .user-profile-header-text {
          padding: 2vh !important;
          flex-direction: column !important;
          justify-content: center !important;

          h1 {
            font-size: 1.2rem;
          }
        }
      }

      .content-scrollable {
        padding: 2vh !important;
      }
    }
  }
}

.user-profile-container {
  background: white;
  padding: 10vh 15vh;
  background-repeat: no-repeat;
  background-image: url('../../assets//images/home-banners/circle-pink.png'), url('../../assets//images/home-banners/circle-pink.png');
  background-position: -10% -58px, 110% -58px;
  
      .form-item-container {
        .ant-form-item-control-wrapper {
          .ant-form-item-control {
            line-height: 22px;
          }
        }
      }

  .user-profile-card {
    // display: flex;
    border: 1px solid #BF914A;
    box-shadow: 0px 3px 10px #0000000D;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;

    .side-container {
      background: #FCF9F4;
      font-family: "montserrat";
      height: 100%;

      .user-profile-sider-header {
        min-height: 145px;
        background-color: $strip-background;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('../../assets//images/dot-background-repeat.svg');
        ;
        background-repeat: no-repeat;
        background-size: cover;

        .circle-logo {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          font-size: 12px;
          color: black;
          line-height: 200px;
          text-align: center;
          background: white;
          position: absolute;
          top: 40px;
          background-image: url('../../assets/images/logo.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 80%;
        }
      }

     
      .ant-menu-item::after {
        border: none;
      }


      .sider-content {
        background-color: #FCF9F4;
        height: auto;
        margin: 0px;
        .ant-menu-item {
          font-family: "Open Sans";
          font-size: 18px;
          display: flex;
          align-items: center;
          height: 119px;
          color: #fff;
          font-weight: normal;
          border-right: none;
          color: #7A4521;          
        }

        li {
          margin: 0;
          border-top: 1px solid rgba(122,69,33, .15);
          border-bottom: 1px solid rgba(122,69,33, .15);
          border-right: none;
          border-left: none;
        }

        .ant-menu-item-selected {
          font-weight: normal;
          background-color: rgba(255,154,0, 0.15);
        }

        .sider-content-header {
          min-height: 320px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          h1 {
            font-weight: bold;
            font-size: 1.2rem;
            padding: 10px;
            text-align: center;
          }

          h5 {
            font-weight: bold;
            color: #8A5B3A;
          }
        }
      }
    }

    .user-profile-content {
      background: #ffffff;

      .user-profile-header {
        display: flex;
        min-height: 145px;
        background: $strip-background;
      }

      .content-scrollable {
        padding: 10vh;
        background: white;
        // height: 70vh;
        overflow-x: auto;

        .ant-select-selection {
          border-top-width: 0;
          border:0;
          box-shadow: none;
        }
        .ant-select-selection--single {
          height: 26px;

          .ant-select-selection__rendered {
            line-height: 28px;
          }
        }

      }

      .user-profile-header-text {
        display: flex;
        justify-content: space-between;
        padding: 0 8vh;
        width: 100%;
        align-items: center;

        h1 {
          color: white;
          font-size: 2rem;
          margin: 0;
        }

        h2 {
          color: lightgray;
          font-size: 1rem;
          margin-top: 12px;
        }
      }

      .user-profile-text-header {

        h1 {
          font-size: 1.6rem;
          font-weight: bold;
        }
      }

      .user-profile-form {

        .ant-input[disabled] {
          color: #404040;
          font-size: 16px;
        }
        
        h4 {
          margin-top: 32px;
          text-transform: uppercase;
          color: #7F7F7F;
          font-size: 16px;
          font-weight: normal;
          font-family: "Open Sans";
        }

        .button-container {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}