@import "../../assets/styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');


.order-summary-item-list {

  .ant-spin-container,
  .ant-spin-nested-loading {
    height: 305px;
    overflow-y: overlay !important;
  }

  .ant-list-footer {
    background-color: #A80F1B;
    color: white;
  }
}

.order-transaction-id {
  color: white
}

.delivery-type {
  text-transform: capitalize;
}


.ant-carousel .slick-dots-bottom {
  bottom: -20px;
  z-index: 100;
}

.slick-dots li button:before {
  font-size: 15px !important;
}

@mixin product-information {
  // display: flex;
  // align-items: center;

  // .product-image {
  //   display: inline-block;
  //   width: 70px;
  //   height: 70px;
  //   background-size: contain;
  //   background-position: center;
  //   background-repeat: no-repeat;
  // }

  // .product-id {
  //   flex: 1;
  //   margin-left: 12px;

  //   h5 {
  //     font-family: 'Nunito';
  //     font-size: 0.75rem;
  //     color: #848484 !important;
  //   }

  //   h3 {
  //     font-family: 'Montserrat';
  //     font-size: 0.875rem;
  //     color: #484848;
  //   }
  //   span {
  //     font-family: 'Montserrat';
  //     font-size: 0.875rem;
  //     color: #898989;
  //   }
  // }
}

@mixin order-header-modal {
  display: flex;
  align-items: center;
  padding: 15px 38px;
  background: $strip-background;

  h1 {
    color: white;
    font-size: 1.5rem;
  }

  p {
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
  }
}

.product-order-content {
  padding-top: 50px;

  .table-header {
    background-color: #fff;

  }

  .product-order-form-page {

    // padding-bottom: 150px!important;
    .ant-card {
      border-color: rgba(0, 0, 0, 0.09);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);

      .ant-card-body {
        padding: 0;
      }
    }

    .header {
      background-color: $strip-background;
      padding: 24px;
      // margin-top: 20px;
      font-family: 'Open Sans';

      h2 {
        flex: 1;
        color: #ffffff;
        margin: 0;
        font-size: 1.75rem;

      }

      a {
        color: white;
        font-size: 14px;
      }

      a:hover {
        font-weight: 450;
        text-decoration: underline;
      }

      @media only screen and (max-width: 520px) and (min-width: 409px)  {
        padding: 12px;
      }

      @media only screen and (max-width: 408px) {
        padding: 6px;

        span {
          font-size: smaller;
        }
      }
    }

    .product-order-result {
      .search-result-indicator {
        font-family: "Open Sans";
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
      }

      .ant-list-bordered {
        border-top-width: 0;
        border-radius: 0;
        border-color: $secondary-border;

        .ant-list-header {
          // margin: 0 -1px;
          background-color: $strip-background-secondary;



          .ant-row {
            //  display: flex;
            // align-items: center;
            gap: 50px;

            h3 {
              // margin: 0;
              text-transform: uppercase;
              font-family: 'Open Sans';
              color: black !important;
              font-weight: 700 !important;
            }
          }

        }

        .ant-list-footer {
          padding: 0px;

          .fixed-bottom {
            position: fixed;
            bottom: 0;
            width: 100%;
            left: 0;
            z-index: 10;
          }

          .ant-row {
            margin: 0 !important;

          }

          .order-totals {
            border-bottom: 1px solid $secondary-border;
            background-color: $strip-background;
            padding-top: 24px;
            padding-bottom: 24px;

            >div>div:first-child {
              padding-left: 12px;
              padding-right: 12px;

              label {
                font-family: 'Montserrat';
                font-weight: 500;
                font-size: 20px;
                color: black;
                padding-left: 20px;
              }
            }

            >div>div:not(:first-child) {
              padding-left: 12px;
              padding-right: 12px;

              label {
                display: block;
                font-family: 'Montserrat';
                font-size: 14px;
                color: black;
              }

              span {
                text-align: center;
                font-family: 'Montserrat';
                font-size: 14px;
                font-weight: bold;
                color: #fff;
              }
            }


          }

          .actions {
            padding: 24px;
            text-align: center;
            background-color: #fff;

            button {
              margin: 0 6px;
            }
          }
        }

        .ant-spin-container {
          max-height: 500px;
          overflow: auto;
        }

        .ant-list-items {
          // new

          .ant-list-item.product {
            border-color: $secondary-border;

            .product-list-row>div:not(:first-child) {
              font-family: 'Montserrat';
              font-size: 0.875rem;
              color: #484848;
            }

            .ant-row-flex {
              flex: 1;

              .ant-col {
                font-weight: bold;

                h5 {
                  color: black;
                  text-transform: uppercase;
                  font-weight: bold;
                  margin: 0;
                }

                h4 {
                  color: $text-primary-color-active;
                  text-transform: uppercase;

                }

                h3 {
                  color: black;
                  margin: 0;
                  text-transform: capitalize;

                }

                &.product-information {
                  @include product-information;
                }

                .ant-form-item {
                  margin: 0;
                }
              }
            }
          }

          // new

          // end new

          .product-information-mobile {
            width: 100%;
            @include product-information;

            // .product-details {
            //   flex: 1;

            //   & > div {
            //     display: flex;
            //     margin-left: 15px;
            //     align-items: center;

            //     .product-id {
            //       margin: 0;
            //       margin-right: 15px;
            //     }

            //     &:last-child {
            //       span {
            //         flex: 1;
            //         text-align: center;

            //         &:first-child {
            //           text-align: left;
            //         }

            //         &:last-child {
            //           text-align: right;
            //         }
            //       }
            //     }
            //   }
            // }
          }
        }
      }
    }
  }
}

.feedback-modal {
  font-family: "Open Sans";

  .ant-modal-close-x {
    color: $text-white-color;
    font-size: 0.9rem;
  }

  .ant-modal-header {
    background-color: $strip-background;

    span {
      color: $text-white-color;
      font-size: 1.2rem;
    }
  }

  h3 {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .feedback-rate {
    min-height: 60px;

    .ant-col {
      border-radius: 50%;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: #b7eb8f;
        border-color: #73d13d;
      }

      &.active {
        border: 1px solid #73d13d;
        background-color: #b7eb8f;
      }

      img {
        width: 55px;
        height: auto;
      }
    }
  }

  .feedback-question {
    h3 {
      font-size: 0.9rem;
      margin-top: 20px;
      margin-bottom: 0px;
    }

    .inputStyle {
      border: 0;
      background: transparent;
      border-bottom: 2px solid #f0e6d8;
      width: 100%;
      box-shadow: 0 2px 2px -2px #f0e6d8;
      padding: 10px 0;
      font-family: 'Montserrat';
      outline: none;
    }
  }

  .feedback-button {
    margin-top: 20px;
  }
}


//Action Button (Mobile View 520px)
@media only screen and (max-width: 610px) {
  .actions {
    padding: 5 !important;
    text-align: center;

    button {
      margin: 5px 0 !important;
      width: 100%;
    }
  }

  // new
}

// Order Form Summary Tablet View
@media (max-width: 1200) {
  .order-product-confirm-modal {
    .ant-modal-content {
      height: 850px;
      overflow-y: overlay;
    }

    .ant-modal-body {
      padding: 0;

      .order-confirmation-header {
        .header-content {
          padding: 20px;
          background-color: #A80F1B;
          display: flex;
          justify-content: space-between;

          h1 {
            color: white;
          }

          .btn-small,
          .btn-primary {
            width: auto;
            height: 30px;
          }
        }
      }

      .order-confirmation-content {
        padding: 0px 10px 24px 0px;
      }

      .os-product-details {
        >div {
          font-family: 'Montserrat';
          margin: 10px 0;
        }

        img {
          margin-top: 5px;
          width: 80px;
          height: 80px;
          object-fit: contain;
        }




        label.brand-name {
          font-family: 'Nunito', sans-serif;
          color: #848484;
          font-size: 0.750rem;
          text-transform: uppercase;
        }

        .brand-variant-name {
          color: #404040;
          font-size: 1rem;
          text-transform: capitalize;
        }

        .psc {
          color: #898989;
          font-size: 0.875rem;
          text-transform: uppercase;
        }

        .aqc label {
          color: #898989;
          font-size: 13px;
          text-transform: uppercase;

        }

        .aqc span {
          color: #404040;
          font-family: 'Montserrat';
          font-size: 14px;
          font-weight: 700;
        }
      }

      .f-total {
        background: $strip-background-secondary;
        font-family: 'Montserrat';
        color: #3a3a3a;
        padding: 10px 40px;
        text-transform: uppercase;

        label {
          font-size: 0.8rem;
          padding-right: 10px;
        }

        span {
          font-size: 0.8rem;
          font-weight: 700;
        }


        .order-submit-footer {
          display: flex;
          flex-direction: column;

        }
      }

      .f-warning-submit {
        padding-top: 20px;
        padding-bottom: 20px;

        >div:first-child {
          display: flex;
          align-items: center;

          img {
            width: 32px;
            height: 32px;
            position: relative;
            top: -3px;
          }

          h3 {
            margin-left: 20px;
          }
        }

      }
    }
  }
}

// Order Form Summary Default
.order-product-confirm-modal {
  width: 950px !important;

  .ant-modal-content {
    // height: 850px;
    overflow-y: overlay;
  }

  .ant-modal-body {
    padding: 0;

    .order-confirmation-header {
      .header-content {
        padding: 20px;
        background-color: #A80F1B;
        display: flex;
        justify-content: space-between;

        h1 {
          color: white;
        }

        .btn-small,
        .btn-primary {
          width: auto;
          height: 30px;
        }
      }
    }

    .order-confirmation-content {
      padding: 0px 10px 24px 0px;
    }

    .os-product-details {
      >div {
        font-family: 'Montserrat';
        margin: 10px 0;
      }

      img {
        margin-top: 5px;
        width: 80px;
        height: 80px;
        object-fit: contain;
      }




      label.brand-name {
        font-family: 'Nunito', sans-serif;
        color: #848484;
        font-size: 0.750rem;
        text-transform: uppercase;
      }

      .brand-variant-name {
        color: #404040;
        font-size: 1rem;
        text-transform: capitalize;
      }

      .psc {
        color: #898989;
        font-size: 0.875rem;
        text-transform: uppercase;
      }

      .aqc label {
        color: #898989;
        font-size: 13px;
        text-transform: uppercase;

      }

      .aqc span {
        color: #404040;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 700;
      }
    }

    .f-total {
      background: $strip-background-secondary;
      font-family: 'Montserrat';
      color: #3a3a3a;
      padding: 10px 40px;
      text-transform: uppercase;

      label {
        font-size: 0.8rem;
        padding-right: 10px;
      }

      span {
        font-size: 0.8rem;
        font-weight: 700;
      }


      .order-submit-footer {
        display: flex;
        flex-direction: column;

      }
    }

    .f-warning-submit {
      padding-top: 20px;
      padding-bottom: 20px;

      >div:first-child {
        display: flex;
        align-items: center;

        img {
          width: 32px;
          height: 32px;
          position: relative;
          top: -3px;
        }

        h3 {
          margin-left: 20px;
        }
      }

    }
  }
}

// ORDER SUMMARY MEDIA CSS

@media (max-width: 1224px) {
  .order-product-confirm-modal {
    width: 750px !important;

    .ant-modal-content {
      height: 750px;
      overflow-y: overlay;
    }
  }
}

@media (max-width: 800px) {
  .order-product-confirm-modal {
    width: 600px !important;

    .ant-modal-content {
      height: 700px;
      overflow-y: overlay;
    }
  }
}

.mobile {

  .product-order-query-form .ant-form,
  .ant-form-horizontal {
    font-size: 12px !important;
    padding: 0px !important;
  }

  .search-result-indicator {
    font-family: "Open Sans";
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    padding-top: 15px;
    color: #777;
  }

  .product-details-xs-img {
    width: 80px !important;
    height: 80px !important;
    object-fit: contain !important;
  }

  .product-image {
    display: block;
    width: 60px;
    height: 60px;
    max-width: 60px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 5px;
  }

  .fixed-b {
    // position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;

    >div>div:first-child {
      background-color: #a90f1b;
      padding: 14px 20px 16px 20px;

      label,
      span {
        color: #fff;
      }

      label {
        font-family: "Open Sans";
        font-size: 0.8rem;
      }

      span {
        font-family: "Montserrat";
        font-size: 0.9rem;
        font-weight: 600;
        padding-top: 5px;
      }
    }

  }

  .clear-savedraft-submit {
    width: 100%;
    background-color: #fff;
    padding: 22px 0px;
    margin: 0;

    button {
      margin: 5px 0px;
      width: 80%;
    }

    .clear,
    .save-as-draft,
    .submit {
      font-family: "Open Sans";
      font-size: 0.8rem;
      border-radius: 50px;
    }

    .clear {
      width: 80px;
      color: #989898;
      font-weight: 700;
      border: none;
      text-align: center;
    }

    .save-as-draft {
      color: #2298E7;
      font-weight: 600;
      border: 1px solid #2298E7;
    }

    .submit {
      color: #fff;
      background-color: #bf914a;
      border: 1px solid #bf914a;
    }
  }

  .ant-list-header {
    display: none;
  }

  .product-order-content {
    padding: 15px;

    .separator {
      display: none;
    }
  }

  .product-order-result {
    .ant-form {
      padding: 0;

      .ant-list-header {
        display: none;
      }
    }
  }

  // new

  .ant-list-items {
    margin-top: 20px;

    .ant-list-item.product {
      margin-top: 5px;
      margin-bottom: 5px;
      padding: 0px;
      border: none;
    }
  }

  .product-info-mobile {
    border: 1px solid black;
    margin: 10px 0px;
    padding: 15px;

    >div:last-child {
      margin-top: 10px;
      font-family: "Montserrat";
      font-size: 15px;
    }

    label {
      color: black;
      font-weight: 700;
      font-size: 12px;
      font-family: "Open Sans";
    }

    span {
      color: #404040;
      text-transform: capitalize;
      font-family: "Montserrat";

    }

    >div:first-child {
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }

  .product-order-query-form {
    padding: 12px !important;
  }

  .product-name {
    font-weight: 600;
    color: #646464;
    font-size: 14px;
  }

  .packing-scheme {
    font-size: 15px;
  }

  .spe-code {
    color: #646464;
  }

  .header {
    a {
      text-transform: uppercase;
      font-size: 0.9em;
      font-family: "Open Sans";
    }
  }
}


// MODALS
@media (max-width: 600px) {

  // DELIVERY DETAILS MODAL START
  .order-delivery-confirm-modal {
    .ant-modal-body {
      height: 550px;
    }

    .order-delivery-header {
      padding: 25px !important;
      font-family: "Montserrat";

      h1 {
        font-size: 14px !important;
      }
    }

    .form-label {
      h3 {
        font-size: 12px !important;
      }
    }

    .inputStyle {
      font-size: 12px !important;
    }

    .ant-card-body {
      padding: 10px;
      height: 450px;
      overflow-y: overlay;
    }

    .btn-small,
    .btn-primary {
      height: 40px !important;
    }

    .btn-container {
      display: flex;
      justify-content: space-evenly !important;
      align-content: center;
      padding: 15px 0;

      button.ant-btn {
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: bold;
        padding: 0px 52px;
        height: 60px;
      }
    }
  }

  // DELIVERY DETAILS MODAL END

  // ORDER FORM SUMMARY START
  .order-product-confirm-modal {
    .ant-modal-content {
      height: 600px !important;
    }

    .order-confirmation-header {

      .header-content {
        h1 {
          font-size: 14px !important;
          color: white;
        }

        // display: flex;
        display: flex;
        justify-content: space-between;
        background-color: #A80F1B;
        padding: 20px !important;

        // justify-content: space-between;
        .btn-small,
        .btn-primary {
          min-width: 20px;
          height: auto;
          font-size: 12px !important;
        }
      }
    }

    .order-confirmation-content {
      height: 580px !important;

      // overflow-y: overlay;
      h3 {
        font-size: 12px !important;
      }

      .order-summary-item-list {
        font-size: 12px !important;

        .ant-spin-container,
        .ant-spin-nested-loading {
          font-size: 12px !important;
          height: 205px;
          overflow-y: overlay !important;
        }

        .ant-list-footer {
          background-color: #A80F1B;
          color: white;
        }
      }

      .btn-small,
      .btn-primary {
        min-width: 20px;
        height: 30px;
        font-size: 12px !important;
      }
    }
  }

  // ORDER FORM SUMMARY END


  // SAP ORDER SUMMARY MODAL START
  .sap-order-summary {
    .ant-modal-body {
      padding: 0;
    }

    .order-delivery-header {
      padding: 20px;
      background-color: #A80F1B;
      color: white;
      font-size: 12px;

      h1 {
        color: white;
      }
    }

    .sap-order-summary-content {
      h3 {
        font-size: 12px !important;
      }

      .order-summary-item-list {
        font-size: 12px !important;
      }
    }
  }

  // SAP ORDER SUMMARY MODAL END
}

// SAP ORDER SUMMARY DEFAULT START

.sap-order-summary {
  .ant-modal-body {
    padding: 0;
  }

  .order-delivery-header {
    padding: 20px;
    background-color: #A80F1B;
    color: white;
    font-size: 12px;

    h1 {
      color: white;
    }
  }
}

// SAP ORDER SUMMARY DEFAULT END


// ORDER FORM SUMMARY DEFAULT SCREEN SIZE START
.order-delivery-confirm-modal {
  .order-delivery-header {
    font-family: "Montserrat";

    h1 {
      font-weight: 600;
      font-family: "Montserrat";
    }
  }

  .ant-modal-body {
    padding: 0;

    .order-delivery-header {
      @include order-header-modal;
    }

    .order-confirmation-content {
      .delivery-details-form {
        .inputStyle {
          border: 0;
          background: transparent;
          border-bottom: 2px solid #f0e6d8;
          width: 100%;
          box-shadow: 0 2px 2px -2px #f0e6d8;
          padding: 10px 0;
          font-family: 'Montserrat';
          outline: none;

          &.no-border {
            border-bottom: 0 !important;
            box-shadow: none;
          }
        }

        .form-label {
          margin: 12px;

          h3 {
            font-size: 16px;
            margin: 0;
            text-transform: uppercase;
            color: #7F7F7F;
            font-family: "Open Sans";
          }

          p {
            margin: 16px 0;
            color: #404040;
            font-family: "Montserrat";
          }

          .no-border {
            color: #404040;
            font-family: "Montserrat";

          }

          input::placeholder {
            color: #b2b2b2;
            opacity: 1;
            /* Firefox */
          }

          .select::placeholder {
            color: #b2b2b2;
            opacity: 1;
            /* Firefox */
          }
        }
      }

      .btn-container {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

// ORDER FORM SUMMARY DEFAULT SCREEN SIZE END





.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.source-input {
  border: 1px solid #cccccc !important;
  width: 100% !important;
  font-size: 70% m !important;
}

.source-container>div {
  width: 100%;
}

/* Input Number */
.term-input {
  width: 100%;
  height: 37px;
  border: 1px solid #B2B2B2;
}

.quantity-input {
  height: 35px;
  margin-bottom: 10px;

  input {
    text-align: center;
    font-weight: bold;
  }
}

// .order-form-qty .ant-input-number-input,
// .order-form-qty-md .ant-input-number-input {
//   padding-left: 25px;
//   padding-right: 25px;
//   padding-bottom: 8px;
//   text-align: center;
//   color: #111;
//   font-weight: bold;
//   font-size: 1em;
//   font-family: "Open Sans";
// }
// .order-form-qty-md .ant-input-number ,
// .order-form-qty .ant-input-number {
//   background-color: transparent;
//   border: 1px solid #cccccc !important;
//   overflow: hidden !important;
//   height: 25px;
// }

// .order-form-qty-md .ant-input-number 
//   {
//     border: 1px solid #cccccc !important;
//     width: 90% !important;
//     height: 70% !important;
// }
// .ant-input-number-handler-down,
// .ant-input-number-handler-up {
//   height: 100% !important;
// }
// .order-form-qty-md  .ant-input-number-handler-up,
// .order-form-qty .ant-input-number-handler-up,
// .order-catalog-input-number-container .ant-input-number-handler-down {
//   position: absolute !important;
//   padding: 17px 0;
//   height: 50px;
// }
.order-form-qty .ant-input-number-handler-down {
  top: 0;
  left: -46px;
}

// .order-form-qty-md  .ant-input-number-handler-down {
// top: -5px;
//  left: -160px;
//  width: 40px !important;
//  height: 100% !important;
//  background-color: black !important;
// }


// .order-form-qty-md .ant-input-number:before,
// .order-form-qty-md .ant-input-number:after ,
// .order-form-qty .ant-input-number:before,
// .order-form-qty .ant-input-number:after {
//   position: absolute;
//   font-size: 24px;
//   font-weight: 100;
//   color: #111;
//   pointer-events: none;
//   z-index: 10;
//   padding: 5px;
//   font-weight: 400;
// }
//  .order-form-qty-md  .ant-input-number:before,
// .order-form-qty .ant-input-number:before {
//   content: "_";
//   top: -20.5px;
//   left: 0px;
//   color: #cccccc;
//  }
// .order-form-qty-md  .ant-input-number:after ,
// .order-form-qty .ant-input-number:after {
//   content: "+";
//   top: -11.5px;
//   right: 0px;
//   color: #cccccc;
// }

// dot-menu

.ant-dropdown-menu.dot-menu {
  top: -22px;
  left: 50px;
  border-radius: 0px;
  font-family: "Montserrat";
  font-size: 1rem;
  color: #000000;

  li {
    padding: 10px 40px 10px 10px;
  }

  li:first-child {
    border-bottom: 1px solid #e8e8e8;
  }

  button {
    border: none;
    outline: none;
  }

  button:disabled,
  button[disabled] {
    border: none;
    outline: none;
    background-color: #e8e8e8;
  }
}

.ant-dropdown-menu.dot-menu:before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 107px;
  width: 25px;
  height: 25px;
  background-color: #fff;
  transform: rotate(-44deg);
}

.xs-order-summary-modal {

  // sub header
  >div>div:first-child {
    background-color: #a80f1b;
    padding: 20px 0px;
    font-family: 'Montserrat';

    h1 {

      font-size: 1rem;
      font-weight: bold;
      color: #fff;
    }

    p {
      margin: 0px;
      font-size: 14px;
      color: #fff;
    }
  }

  // details
  >div>div:nth-child(2) {
    >div {
      overflow: auto;
      height: 450px;

      .delivery {

        border-bottom: 1px solid #0000000d;

        // header
        >div>div:first-child {
          font-family: "Montserrat";
          padding: 25px 0px;

          h2 {
            color: #404040;
            margin: 0px;
            text-transform: capitalize;

          }

          >div:first-child {
            h2 {
              font-size: 15px;
              font-weight: 600;

            }
          }

          >div:last-child {
            h2 {
              font-size: 14px;
            }

            h2:focus {
              color: #a90f1b;
            }
          }
        }

        // body
        >div>div:nth-child(2) {
          >div {
            padding: 10px 0;
            font-family: "Open Sans";

            label {
              display: block;
              font-size: 12px;
              color: #b2b2b2;
              text-transform: uppercase;
              margin-bottom: 5px;
            }

            span {
              font-size: 14px;
              font-weight: 600;
              color: #848484;
            }
          }
        }
      }

      .product {

        >div>div:first-child {
          font-family: "Montserrat";
          padding: 25px 0px;

          h2 {
            color: #404040;
            margin: 0px;
            text-transform: capitalize;

          }

          >div:first-child {
            h2 {
              font-size: 15px;
              font-weight: 600;
            }
          }

          >div:last-child {
            h2 {
              font-size: 14px;
            }

            h2:focus {
              color: #a90f1b;
            }
          }
        }

        // body
        >div>div:nth-child(2) {
          label {
            font-family: "Open Sans";
            font-size: 14px;
            color: #b2b2b2;
            text-transform: uppercase;
            margin-bottom: 5px;
          }

          span {
            font-family: 'Montserrat';
            font-family: "Open Sans";
            font-size: 15px;
            font-weight: 600;
            color: #000;
          }

          .variant-name {
            padding: 2px 0;
          }
        }

        .amount-qty-cbm {
          padding-top: 10px;
        }
      }

      // content
      >div>div>div:nth-child(2) {
        padding-bottom: 30px;
      }

      >div:first-child {
        // background-color: orange;
      }
    }
  }

  // sub footer
  >div>div:nth-child(3) {
    >div:first-child {
      background-color: #f5f5f5;
      padding: 20px 0;

      label {
        display: block;
        text-transform: uppercase;
        font-family: "Open Sans";
        font-size: 0.9rem;
        color: #656565;
      }

      span {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 1.05rem;
        color: #323232;
      }
    }

    .cancel-confirm-btns {
      padding: 15px 0;

      >div:first-child>button {
        border: 1px solid #A2A2A2;
      }
    }
  }
}

.bulk-upload-success-modal {
  .ant-modal-content {
    .ant-modal-body {
      .ant-modal-confirm-body-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .ant-modal-confirm-body {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            margin: 20px;
          }

          .anticon {
            font-size: 100px;
            margin: 22px !important;
          }

          .ant-modal-confirm-content {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.bulk-upload-container {
  .bulk-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;

    h4 {
      text-transform: uppercase;
      color: #999999;
      font-size: 22px;
    }
  }

  .bulk-description {
    display: flex;
    flex-direction: column;
    text-align: center;

    span {
      color: #898989;
      font-size: 16px;
    }
  }

  .bulk-sample-download {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 25px;

    a {
      color: #2298E7;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .bulk-error {
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 10px 20px;
    background: rgba(227, 6, 18, 0.1);
    // opacity: 0.3;
    border: 2px solid #FF000E;
    box-sizing: border-box;
    border-radius: 21px;

    h4 {
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: #E30612;
    }

    span {
      font-size: 16px;
      color: #E30612;
      opacity: 0.7;
    }
  }

  .uploader-btn {
    margin: 20px;

    .ant-upload {
      width: 100%;

      button {
        width: 100%;
        height: 100px;
        border: 1px dashed rgb(145, 138, 127);
        border-radius: 21px;
        background: rgba(221, 144, 27, 0.03);

        span {
          background: #BF914A;
          border-radius: 46px;
          color: white;
          width: 180px;
          height: 36.11px;
          padding: 7px;
        }
      }
    }
  }

  .bulk-submit {
    .clear-btn {
      margin: 10px;
      color: gray;
      width: 150px;
      height: 42px;
      background: transparent;
      border-radius: 46px;
      border-color: lightgray;
    }

    .submit-btn {
      margin: 10px;
      color: 'white';
      width: 208px;
      height: 42px;
      background: #BF914A;
      border-radius: 46px;
      border-color: lightgray;
    }
  }
}

.product-details-xs-img {
  width: 80px !important;
  height: 80px !important;
  object-fit: contain !important;
}


@media (max-width: 375px) {
  .product-image {
    width: 65px !important;
    height: 65px !important;
  }
}

.order-clear {
  border: 1px solid #989898 !important;
}

.order-clear:disabled {
  opacity: 0.5;
}



.delivery-details-form {
  .select-holder {
    position: relative;
  }

  .select-holder:before,
  .select-holder:after {
    content: "";
    position: absolute;
  }

  .select-holder:before {
    bottom: 26px;
    right: 5px;
    width: 10px;
    height: 10px;
    background-color: #b2b2b2;
    transform: rotate(-45deg);
    z-index: -0;
  }

  .select-holder:after {
    bottom: 30px;
    right: 2px;
    width: 22px;
    height: 15px;
    background-color: #fff;
    z-index: 2;
  }

  .inputStyle {
    font-family: "Montserrat";
    font-size: 16px;
    letter-spacing: -0.42px;
    color: #404040;
    text-transform: capitalize;
    border-bottom: 2px solid #DFC8A5 !important;
    position: relative;
    outline: none;
  }

  .form-label {
    margin: 12px 12px 20px 12px !important;

    h3 {
      font-family: "Open Sans";
      font-size: 12px;
      letter-spacing: -0.42px;
      color: #7F7F7F !important;
    }

    p {
      font-family: "Open Sans";
      font-size: 1rem;
      color: #404040;
      margin-top: 7px !important;
      margin-bottom: 20px !important;
    }

    input::placeholder {
      color: #b2b2b2;
      opacity: 1;
      /* Firefox */
    }

    .select::placeholder {
      color: #b2b2b2;
      opacity: 1;
      /* Firefox */
    }
  }
}

.ant-message {
  margin-top: 30vh !important;

  .ant-message-notice {
    font-size: 18px !important;
    font-weight: bold;
  }

  .anticon {
    font-size: 25px;
  }
}