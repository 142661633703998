@import '../assets/styles/variables';

.main-layout {
    // zoom: 85%;

    .search-container {
 
        .ant-input-affix-wrapper {
            .ant-input,  .ant-input:focus, .ant-input:hover {
                border: 1px solid #B2B2B2;
                box-shadow: none;

            }
            .ant-input-prefix svg {
                color: #A7A7A7;
            }
            .ant-input::placeholder {
                color: #777777;
            }
        }
   

        .ant-input[type="text"] {
          font-family: 'Montserrat';
          font-size: 0.875rem;
          color: #404040;
          padding-left: 35px;
          height: 35px;
        }
  
        .ant-input-search-icon {
          display: none;
        }
        .ant-input-prefix svg {
          font-size: 1rem;
          height: 40px;
          color: #b2b2b2;
        }
        .ant-select-selection {
            font-family: "Montserrat";
             height: 35px;
             border: 1px solid #B2B2B2;
             font-size: 14px;

             svg{
                color: #A7A7A7;
             }
         }

         .ant-select-selection__placeholder {
            color: #707070;
         }

        .ant-select-selection__rendered {
             line-height: 32px;  
        }
      }

    &>.ant-layout-header {
        position: fixed;
        z-index: 2;
        width: 100%;
    }

    &>.main-layout-content {
        margin-top: 64px;
        position: relative;
        z-index: 1;
    }

	

    &.home {
        &>.ant-layout-header {
            padding-right: 0px;
            padding-left: 50px;
            .ant-menu {
                background-color: transparent;
            }

            .logo{
                filter: none;
            }

            &:not(.scrolled) {
                background-color: transparent;
                box-shadow: none;
                .logo{
                    filter: brightness(0) invert(1);
                }
                background: rgba(30, 28, 28, 0.88);
                .ant-menu {
                    .ant-menu-item:not(.ant-menu-item-selected) {
                        a {
                            color: #ffffff;
                        }
                    }
                }
            }

            &.mobile {
                .drawer-toggle {
                    color: #FFFFFF;
                }

                .logo {
                    background-image: url('../assets/images/logo-white.png');
                }

                &.scrolled {
                    .drawer-toggle {
                        color: $text-primary-color-active;
                    }

                    .logo {
                        background-image: url('../assets/images/logo.png');
                    }
                }
            }
        }

        &>.main-layout-content {
            margin-top: 0;
        }
    }


}