.confirm-password-container {
  display: flex;
  justify-content: center; 
  align-items: center;
  height: 500px;

  .card-width {
    width: 400px;

    .ant-input, .ant-input:hover {
      border: 0;
      outline: 0;
      background: transparent;
      border-bottom: 2px solid rgba(191,145,74, .49) ;
      box-shadow: none !important;
      width:  100%;
      height: 40px;
      padding: 0;
    }

    h1 {
      margin-bottom: 16px;
    }

    .ant-form-item-label {
      line-height: 20px;
    }

    .ant-form-item {
      margin: 16px 0px;
    }

    button {
      margin-top: 15px;
    }
  }

  .btn-block {
    width:100%;
    margin-top: 30px;
  }
}