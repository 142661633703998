.forgot-password-container {
  display: flex;
  justify-content: center; 
  align-items: center;
  height: 400px;

  .card-width {
    width: 400px;
  }

  .login-form {
    input {
      border: 0;
      outline: 0;
      background: transparent;
      border-bottom: 2px solid rgba(191,145,74, .49);
      box-shadow: none !important;
      width:  100%;
      height: 40px;
      padding: 0;
    }

    button {
      margin-top: 10px;
    }
  }

}